@import url("https://fonts.cdnfonts.com/css/codec-pro");

.MuiPopover-paper {
    border-radius: 16px !important;
    margin-top: 5px;
}

.MuiAvatarGroup-avatar {
    width: 32px !important;
    height: 32px !important;
    border: transparent !important;
}

.inter-font {
    font-family: "Inter", sans-serif;
}

.inter-font-medium {
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

.inter-font-bold {
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body {
    height: 100%;
    margin: 0;
    font-family: "Inter", sans-serif;
    color: #434f5c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none !important;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

* {
    outline: none !important;
}

.MuiBadge-colorError {
    background-color: #05c46b !important;
    color: white !important;
    margin: -2px;
}

.marginTop15 {
    margin-top: 15px !important;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.alignBaseline {
    display: flex;
    align-items: baseline;
}

a {
    color: unset !important;
}

.inputSelect {
    padding: 7px 2px !important;
    border: none;
    outline: none;
    background: transparent;
    width: auto !important;
}

.displayFlex {
    display: flex !important;
}

.alignEnd {
    display: flex;
    align-items: flex-end;
}

.marginCenter {
    margin-left: auto !important;
    margin-right: auto !important;
}
/* 
.react-form-builder {
    height: 500px !important;
} */

.react-form-builder-preview {
    background-color: white !important;
    border: none !important;
    box-shadow: none !important;
    height: 100%;
    overflow-y: scroll !important;
    position: absolute;
}

.clearfix {
    position: relative;
}

.react-form-builder-preview::-webkit-scrollbar {
    display: none;
}

.react-form-builder-toolbar {
    margin-top: -140px !important;
    width: 25% !important;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #fafbfd !important;
}

.react-form-builder-toolbar::-webkit-scrollbar {
    display: none;
}

.react-form-builder .react-form-builder-toolbar ul li {
    font-family: "Inter", sans-serif;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: black !important;
    background: #ffffff !important;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.03) !important;
    border-radius: 8px !important;
    border: none !important;
    margin-bottom: 8px !important;
}

.react-form-builder-preview .Sortable h3 {
    font-family: "Inter", sans-serif;
    color: black !important;
}

.react-form-builder .react-form-builder-toolbar ul li i {
    color: #05c46b;
    width: 11px;
    height: 11px;
}

.ant-notification {
    z-index: 1999 !important;
}

@media (max-width: 768px) {
    .react-form-builder-toolbar h4 {
        color: black !important;
        background-color: white !important;
    }
}

.form-place-holder {
    visibility: hidden;
}

.form-place-holder:after {
    content: "Drag and drop or click the buttons on the right to add content.";
    font-family: "Inter", sans-serif;
    visibility: visible;
    color: black;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.4;
}

.placeholder-drop .form-place-holder:after {
    content: "";
}

.placeholder-drop {
    height: 30px;
}

.react-form-builder-form p {
    font-size: 16px !important;
    color: black !important;
    line-height: 24px !important;
    margin-bottom: 5px !important;
}

.react-form-builder-form .rfb-item label {
    color: black !important;
}

.react-form-builder-form .rfb-item {
    padding: 5px 0 !important;
    position: relative;
}

.react-form-builder-form .rfb-item img {
    width: 100%;
}

.btn-toolbar input {
    background-color: #05c46b;
    border-radius: 8px;
    color: white;
}

.ant-drawer {
    position: absolute;
}

.ant-drawer .ant-drawer-content {
    height: 90vh;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none;
}

.ant-drawer-content {
    background-color: #f9fafb;
    color: #161616;
    top: 104px;
}

.ant-spin-container::after {
    background: transparent !important;
}

.MuiTableCell-root {
    border-bottom: 1px solid rgb(151, 151, 160, 0) !important;
}

.MuiDialog-paper {
    border-radius: 16px !important;
}

.ant-drawer-close {
    top: -5px;
    outline: none !important;
}

.ant-table-thead > tr > th {
    height: 34px !important;
    background: #fafbfd !important;
    padding: 10px !important;
    font-family: "Inter", sans-serif;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.05em !important;
    color: #7b8497 !important;
    text-transform: uppercase !important;
}

.ant-table-column-sorters {
    height: 14px !important;
    padding-left: 0px !important;
    font-family: "Inter", sans-serif;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.05em !important;
    padding: 0px !important;
    color: #7b8497 !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: #05c46b !important;
}

.ant-table-thead > tr th:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.ant-table-thead > tr th:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.ant-table-thead > tr > th:hover {
    cursor: pointer !important;
}

.ant-table-tbody > tr > td {
    height: 0px !important;
    font-family: "Inter", sans-serif;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #141414 !important;
    padding-left: 10px !important;
    text-transform: capitalize !important;
    /* z-index: 0; */
}


.ant-table-tbody > tr:hover > td {
    cursor: pointer !important;
    color: #05c46b !important;
    /* background: transparent !important; */
}

.ant-table-tbody > tr > td > p {
    margin-bottom: 0px !important;
}

.ant-pagination-item-active {
    border-color: #05c46b;
}

.table-loader-container {
    background: #ffffff !important;
}

@media (prefers-color-scheme: dark) {
    .header-menu-button{
		color: white !important;
	}
    .MuiPopover-paper {
        background: #23242a !important;
    }
    .mob-button-header {
		color: white !important;
   }
  

    .body,
    body {
        background: #121212 !important;
        color: white !important;
    }

    .ant-table-tbody > tr > td > p {
        color: white;
    }

    .ant-drawer-content,
    .ant-drawer-header {
        background-color: #121212;
    }

    .ant-drawer-header {
        border-bottom: 3px solid rgb(151, 151, 160, 0.1) !important;
    }

    .ant-drawer-title {
        color: #ffffff;
    }

    .ant-drawer-close {
        color: #ffffff;
    }

    .ant-drawer-close:hover {
        color: rgb(255, 255, 255, 0.6);
        transition: 0.3s ease all;
    }

    /* commented this as has issues on other elements like modals on templates */
    /* .MuiPaper-rounded {
		border-radius: 0 !important;
	} */
    .drawerCloser {
        background-color: #f9fafb;
        box-shadow: none;
        padding-left: 15px;
        color: #434f5c;
    }

    .react-form-builder-preview {
        background: #1c1c1e !important;
        color: white !important;
    }

    .react-form-builder-preview .Sortable h3 {
        color: white !important;
    }

    .form-place-holder:after {
        color: white;
    }

    .react-form-builder-toolbar {
        background: #121212 !important;
    }

    .react-form-builder-toolbar h4::after {
        color: white;
    }

    .react-form-builder .react-form-builder-toolbar ul li {
        color: white !important;
        background: #23242a !important;
        box-shadow: 0px 1px 32px rgba(0, 0, 0, 0.04),
            0px 1px 7px rgba(0, 0, 0, 0.03) !important;
    }

    .react-form-builder
        .react-form-builder-preview
        .Sortable
        .rfb-item.SortableItem {
        background: transparent !important;
        color: white !important;
    }

    .react-form-builder
        .react-form-builder-preview
        .Sortable
        .rfb-item.SortableItem
        i {
        color: white !important;
    }

    .react-form-builder-form .rfb-item p {
        color: white !important;
    }

    .ant-table {
        background: transparent !important;
        color: rgba(255, 255, 255, 0.87) !important;
    }

    .ant-empty-description {
        color: white;
    }

    .ant-table-thead > tr > th {
        background: #23242a 60% !important;
        color: rgba(255, 255, 255, 0.6) !important;
        box-shadow: 0px 1px 32px rgba(0, 0, 0, 0.04),
            0px 1px 7px rgba(0, 0, 0, 0.03);
        border-bottom: none !important;
    }

    .ant-table-tbody > tr > td {
        color: rgba(255, 255, 255, 0.87) !important;
        border-bottom: 1px solid rgb(255, 255, 255, 0.1) !important;
        background: #121212 !important;
    }

    .ant-progress-inner {
        background: #23242a !important;
    }

    .ant-progress-text {
        color: rgb(255, 255, 255, 0.87) !important;
    }

    .ant-pagination-item {
        background: transparent !important;
        color: white !important;
        border: 1px solid rgb(255, 255, 255, 0.1) !important;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        background: transparent !important;
        border: 1px solid rgb(255, 255, 255, 0.1) !important;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link svg {
        color: white !important;
    }

    .ant-table-column-sort {
        background: transparent !important;
    }

    .css-1eg0sfo-skeletonStyles-Skeleton {
        background-color: #171718 !important;
        background-image: linear-gradient(
            90deg,
            #171718,
            #161616,
            #171718
        ) !important;
    }

    .ant-divider-horizontal {
        color: white !important;
    }

    .table-loader-container {
        background: #1c1c1e !important;
    }

    .form-control:focus {
        background: rgb(35 36 42) !important;
        color: #ffffff !important;
        box-shadow: 0 0 0 0.2rem rgb(35 36 42 / 25%);
    }

    .MuiDivider-root {
        background: rgb(35 36 42) !important;
    }

    .react-form-builder-form .rfb-item label {
        color: white !important;
    }

    .clearfix {
        color: #7b8497 !important;
    }

    .clearfix .edit-form h4 {
        color: #fff !important;
    }

    label {
        color: #ffffff !important;
    }

    .edit-form {
        background-color: #23242a !important;
    }

    .edit-form .DraftEditor-editorContainer {
        color: #000;
        /* background-color: #1c1c1e!important;
		border-radius: 5px !important;
		padding: 10px; */
    }

    .rdw-editor-main {
        background: #23242a !important;
    }

    .rdw-editor-main span {
        color: #ffffff;
    }

    .edit-form div:first-child .form-group {
        background-color: #1c1c1e !important;
        border-radius: 5px !important;
        padding: 10px;
    }

    .edit-form div:first-child .dynamic-option-list {
        background-color: #1c1c1e !important;
        border-radius: 5px !important;
        padding: 10px;
    }

    .edit-form input {
        background: #23242a;
        border: 1px solid hsla(0, 0%, 100%, 0.1);
    }

    .ant-pagination-item-active a {
        color: #05c46b !important;
    }

    .SortableItem h3 {
        color: white !important;
    }

    .react-form-builder
        .react-form-builder-preview
        .edit-form
        .dynamic-option-list
        ul
        li
        input.form-control {
        background: transparent !important;
    }

    .react-form-builder .react-form-builder-preview .edit-form-wrapper {
        background: #23242a !important;
    }

    .edit-form-wrapper .toolbar-header .header-title {
        color: white !important;
    }

    .edit-form-wrapper textarea {
        background-color: transparent !important;
    }

    .react-form-builder .react-form-builder-toolbar .toolbar-header h4 {
        color: #7b8497 !important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #23242a !important;
        color: white !important;
        /* border: 1.5px solid rgb(123, 132, 151, 0.15); */
    }

    .ant-input-number-input {
        background: #23242a !important;
        /* color: white !important; */
    }
}

.delete_popup_set .MuiDialog-scrollPaper {
    align-items: flex-start;
}

div#Assing-popper {
    left: 70px !important;
}

.MuiDialog-root.Delete_folder .MuiDialog-scrollPaper {
    align-items: flex-start;
}

::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #05c46b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    border-radius: 6px;
    background: #05c46b;
}

/*checklist datatabe */
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.height-50vh{
    height: 50vh !important;
}

.toolbar-height{
    height: calc(100vh - 190px) !important;
    overflow: auto !important;
}
/* 
tr.ant-table-measure-row {
    display: none;
} */

#template-popper,
#filter-popper,
#column-popper, 
#export-popper {
    z-index: 100 !important;
}

#react-select-styles *{
	color: #333;
}