.container{
  padding: 7%;
  width: 100%;
  min-height: calc(100vh - 104px);
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content{
 text-align: center;
}

.loadingText{
  font-family: 'Inter', sans-serif;
  color: #8e9eac;
  font-size: 17px;
  font-weight: 400;
  line-height: 17px;
  margin: 15px;
}

.logoloader{
  animation: ninja 1500ms infinite;
  animation-timing-function: ease-in-out;
  }
  
  @keyframes ninja{
	0%{
    transform: scale(0.8);
    opacity: 0.9;
  }
  50%{
    opacity: 1;
    transform: scale(1);
  }
  100%{
    opacity: 0.9;
    transform: scale(0.8);
  }
  }

  @media (prefers-color-scheme: dark) {
    .container{
      background: #121212 !important; 
    }
    .loadingText{
      color:white;
    }
  }
  